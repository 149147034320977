export const dimmedRed = '#A31839';
export const dimmedBlue = '#A4CADE';
export const dimmedYellow = '#E3D07D';
export const dimmedGrey = '#646464';
export const dimmedGreen = '#7EA69B';
export const disabledGrey = '#00000042';

export const schaefflerColor = '#00893D';
export const demandValidationPartialWeekColor = '#EBEEF0';
export const demandValidationEditableColor = '#CCE8D9';
export const demandValidationToSmallColor = '#FDE7A3';
export const demandValidationWrongInputColor = '#DFADB9';
export const demandValidationInFixZoneColor = '#DEE3E7';
export const borderColor = '#00000052'; // #000000 with 32% opacity
export const borderColorDark = '#19191999'; // #191919 with 60% opacity
export const textDarkGrey = '#000000DE'; // #000000 with 87% opacity
export const textLightGrey = '#00000099'; // #000000 with 60% opacity
export const textVeryLightGrey = '#0000007F'; // #000000 with 50% opacity
export const disableColor = '#e0e0e0';
export const textLight = '#FFFFFF';
export const warningColor = '#F5A623';
export const errorColor = '#A31839';
export const errorColorLight = '#F7D4D4';
export const secondaryColor = dimmedGrey;
export const grayBackgroundColor = '#DEE3E7';

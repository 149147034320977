import { addMonths, startOfMonth, startOfWeek, subMonths } from 'date-fns';
import de from 'date-fns/locale/de';

import { DateRangePeriod } from '../../core/dateRange';

import { KpiBucketType } from './model';

export const firstEditableDate = (period: DateRangePeriod) => {
  switch (period) {
    case 'MONTHLY':
      return startOfMonth(new Date());
    case 'WEEKLY':
      return startOfWeek(new Date(), { locale: de });
  }
};

export const firstEditableDateForBucket = (bucketType: KpiBucketType) => {
  switch (bucketType) {
    case 'MONTH':
      return startOfMonth(new Date());
    case 'WEEK':
    case 'PARTIAL_WEEK':
      return startOfWeek(new Date(), { locale: de });
  }
};

export const lastEditableDate = () => addMonths(new Date(), 36);

export const firstViewableDate = () => subMonths(new Date(), 36);
export const lastViewableDate = () => addMonths(new Date(), 36);

import useSWR from 'swr';

import { requestFromApi } from '../../core/requests/httpClient';

import { ForecastInfo } from './model';

export default function useForecastInfo(
  customerNumber: string | undefined,
  materialNumber: string | undefined,
) {
  const result = useSWR<ForecastInfo>(
    customerNumber && materialNumber
      ? `demand-validation/material-infos/${customerNumber}/${materialNumber}`
      : null,
    requestFromApi,
    {
      revalidateOnFocus: false,
    },
  );

  return result;
}

import {
  CellClassParams,
  CellStyle,
  ColDef,
  EditableCallbackParams,
  ICellRendererParams,
  ValueGetterParams,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { format, isAfter, isBefore, parseISO } from 'date-fns';
import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { numberValueFormatterAgGrid } from '../../../agGrid/formatter';
import { clientSideTableDefaultProps, getDefaultColDef } from '../../../agGrid/gridDefaults';
import useAutosizeColumns from '../../../agGrid/useAutosizeColumns';
import DataHint from '../../../components/DataHint';
import { StyledGridSection } from '../../../components/StyledGridSection';
import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner';
import { checkRoles, demandValidationChangeAllowedRoles } from '../../../core/auth/roles';
import { t } from '../../../core/i18n/i18n';
import { preferredDateFormatWithoutDay } from '../../../core/i18n/l10n';
import { strictlyParseFloat } from '../../../core/number';
import { firstEditableDateForBucket } from '../../../domain/demandValidation/limits';
import {
  KpiBucketType,
  KpiData,
  KpiEntry,
  MaterialListEntry,
} from '../../../domain/demandValidation/model';
import { PlanningView } from '../../../domain/demandValidation/planningView';
import useForecastInfo from '../../../domain/demandValidation/useForecastInfo';
import { useUserData } from '../../../domain/user/useUserData';
import {
  demandValidationEditableColor,
  demandValidationInFixZoneColor,
  demandValidationPartialWeekColor,
  demandValidationToSmallColor,
  demandValidationWrongInputColor,
  schaefflerColor,
  textVeryLightGrey,
} from '../../../styles/colors';
import DemandValidationKpiHeader from '../DemandValidationKpiHeader';

import MoreInformation from './MoreInformation';
import ToolbarKpiTable, { FilterValues } from './ToolbarKpiTable';
import { getCellStyleFunc } from './cellStyle';
import {
  kpiColumnDefinitionsConfirmed,
  kpiColumnDefinitionsRequested,
} from './kpiColumnDefinitions';

type DemandValidationTableProps = {
  kpiData: KpiData | undefined;
  kpiError: string | undefined;
  materialListEntry: MaterialListEntry | undefined;
  onClickHeader: (data: KpiEntry) => void;
  onUpdateValidatedForecast: (
    date: string,
    value: string,
    bucketType: KpiBucketType,
    customerNumber: string,
    materialNumber: string,
  ) => void;
  contentGridRef: MutableRefObject<AgGridReact | undefined> | undefined;
  planningView: PlanningView;
};

function DemandValidationTable({
  kpiData,
  kpiError,
  materialListEntry,
  onClickHeader,
  onUpdateValidatedForecast,
  contentGridRef,
  planningView,
}: DemandValidationTableProps) {
  const { resizeColumns } = useAutosizeColumns(contentGridRef?.current);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    deliveries: true,
    firmBusiness: true,
    opportunities: true,
    forecastProposal: true,
    forecastProposalDemandPlanner: true,
    indicativeDemandPlanning: true,
    currentDemandPlan: true,
    activeAndPredecessor: false,
  });

  const columnDefinitions =
    planningView === PlanningView.REQUESTED
      ? kpiColumnDefinitionsRequested
      : kpiColumnDefinitionsConfirmed;

  const { roles } = useUserData();
  const authorizedToChange = checkRoles(roles, demandValidationChangeAllowedRoles);
  const { data: forecastInfo } = useForecastInfo(
    materialListEntry?.customerNumber,
    materialListEntry?.materialNumber,
  );

  const dataLoaded = useMemo(
    () => Boolean(kpiData) && Boolean(forecastInfo),
    [kpiData, forecastInfo],
  );

  const updateData = useCallback(
    (params: ValueSetterParams, kpiData: KpiData): boolean => {
      const rowKey = (params.data as (typeof columnDefinitions)[number]).key(filterValues);
      if (!rowKey) return false;

      const date = params.column.getColId();
      const kpiEntryIndex = kpiData.data.findIndex((entry) => entry.fromDate == date);
      if (kpiEntryIndex == -1) return false;
      const parsedValue = strictlyParseFloat(params.newValue);
      const writeValue = parsedValue ? parsedValue : params.newValue;
      kpiData.data[kpiEntryIndex][rowKey] = writeValue;
      return true;
    },
    [filterValues],
  );

  const isEditable = useCallback(
    (
      params: EditableCallbackParams | CellClassParams | ValueSetterParams,
      data: KpiEntry,
    ): boolean => {
      // OP materials are not editable
      if (materialListEntry?.materialClassification == 'OP') return false;

      if (!authorizedToChange) return false;

      // check if row is editable, if not check no further
      if (!(params.data as (typeof columnDefinitions)[number]).editable) return false;

      const fromDateCurrentColumn = parseISO(data.fromDate);
      return !isBefore(fromDateCurrentColumn, firstEditableDateForBucket(data.bucketType));
    },
    [authorizedToChange, materialListEntry?.materialClassification],
  );

  const editable = useCallback(
    (data: KpiEntry) => (params: EditableCallbackParams) => {
      return isEditable(params, data);
    },
    [isEditable],
  );

  const validatedForecastSetter = useCallback(
    (kpiData: KpiData, data: KpiEntry) => (params: ValueSetterParams) => {
      if (params.oldValue == params.newValue && params.newValue != '') return false;
      if (!isEditable(params, data)) return false;

      if (data.storedBucketType == 'WEEK' && data.bucketType == 'MONTH') {
        const result = confirm(
          t('validation_of_demand.confirm.override_week_by_month', {
            date: format(parseISO(data.fromDate), preferredDateFormatWithoutDay),
          }),
        );
        if (!result) return false;
      }

      const updated = updateData(params, kpiData);
      if (updated) {
        onUpdateValidatedForecast(
          params.column.getColId(),
          params.newValue,
          data.bucketType,
          kpiData?.customerNumber,
          kpiData?.materialNumber,
        );
        return true;
      } else return false;
    },
    [isEditable, onUpdateValidatedForecast, updateData],
  );

  const colorCell = useCallback(
    (data: KpiEntry) =>
      (params: CellClassParams): CellStyle | null => {
        const editable = isEditable(params, data);
        const fromDateCurrentColumn = parseISO(data.fromDate);
        const inFixZone =
          materialListEntry?.fixHor &&
          isAfter(parseISO(materialListEntry.fixHor), new Date()) &&
          !isBefore(fromDateCurrentColumn, firstEditableDateForBucket(data.bucketType)) &&
          (materialListEntry?.stochasticType == 'E' || materialListEntry?.stochasticType == 'C') &&
          !isAfter(fromDateCurrentColumn, parseISO(materialListEntry?.fixHor));
        const parsedFloat = strictlyParseFloat(params.value);
        const isPositiveFloat = parsedFloat >= 0;
        const forecastTooSmall = parsedFloat < calculateFirmBusinessAndDeliveries(data);
        const rowKey = (params.data as (typeof columnDefinitions)[number]).key(filterValues);

        if (inFixZone && rowKey == 'currentDemandPlan') {
          return {
            backgroundColor: demandValidationInFixZoneColor,
          };
        }
        if ((params.data as (typeof columnDefinitions)[number]).editable) {
          if (params.value && !isPositiveFloat) {
            return {
              backgroundColor: demandValidationWrongInputColor,
            };
          }
          if (forecastTooSmall) {
            return {
              backgroundColor: demandValidationToSmallColor,
            };
          }
          if (editable) {
            return {
              backgroundColor: demandValidationEditableColor,
            };
          }
        }
        if (data.bucketType == 'PARTIAL_WEEK') {
          return {
            backgroundColor: demandValidationPartialWeekColor,
          };
        }
        return null;
      },
    [isEditable, materialListEntry, filterValues],
  );

  function calculateFirmBusinessAndDeliveries(data: KpiEntry): number {
    const deliveries = data.deliveriesActive || 0;
    const firmBusiness = data.firmBusinessActive || 0;
    return deliveries + firmBusiness;
  }

  const columnDefs = useMemo(() => {
    return [
      {
        ...getDefaultColDef(),
        headerName: t('validation_of_demand.planning_table.kpi', {}),
        valueGetter: (params: ValueGetterParams) => {
          const title = (params.data as (typeof columnDefinitions)[number]).title(
            filterValues,
            materialListEntry?.materialClassification,
          );

          return t(title, {});
        },
        cellRenderer: (params: ICellRendererParams) => (
          <TitleRenderer
            {...params}
            materialClassification={materialListEntry?.materialClassification}
            columnDefinitions={columnDefinitions}
          />
        ),
        pinned: true,
        width: 300,
      },
      ...((kpiData?.data.map((data) => {
        return {
          ...getDefaultColDef(),
          editable: editable(data),
          key: data.fromDate,
          colId: data.fromDate,
          valueGetter: (params: ValueGetterParams) => {
            const index = (params.data as (typeof columnDefinitions)[number]).key(
              filterValues,
              materialListEntry?.materialClassification,
            );
            if (!index) return undefined;
            else return data[index];
          },
          valueFormatter: numberValueFormatterAgGrid,
          valueSetter: validatedForecastSetter(kpiData, data),
          cellStyle: (params: CellClassParams) => {
            return {
              ...(colorCell(data)(params) || {}),
              ...(getCellStyleFunc(
                data.bucketType,
                materialListEntry?.currentRLTSchaeffler,
                materialListEntry?.materialClassification,
                forecastInfo,
              )(params) || {}),
            };
          },
          headerComponentParams: {
            kpiEntry: data,
            onClickHeader: onClickHeader,
          },
        };
      }) as ColDef[]) || []),
    ];
  }, [
    colorCell,
    filterValues,
    forecastInfo,
    editable,
    validatedForecastSetter,
    kpiData,
    materialListEntry,
    onClickHeader,
    columnDefinitions,
  ]);

  return (
    <DemandValidationTableWrapper>
      {!dataLoaded && !kpiError && <LoadingSpinner />}
      {kpiError && <DataHint text={kpiError} />}

      {!kpiError && (
        <ToolbarKpiTable filterValues={filterValues} onFilterValuesChanged={setFilterValues} />
      )}

      <StyledGridSection
        // hide table until it finished loading (and layouting) and show it together with the forecastInfo
        // if there is an error, do not show it either
        hidden={!dataLoaded || Boolean(kpiError)}
      >
        <AgGridReact
          {...clientSideTableDefaultProps}
          ref={contentGridRef && ((ref) => ref && (contentGridRef.current = ref))}
          rowData={[...columnDefinitions].filter((row) => row.visible(filterValues))}
          defaultColDef={{
            sortable: false,
            suppressMovable: true,
            suppressMenu: true,
            headerComponent: DemandValidationKpiHeader,
          }}
          suppressCsvExport
          onFirstDataRendered={() => resizeColumns()}
          onNewColumnsLoaded={() => {
            resizeColumns();
          }}
          columnDefs={columnDefs}
        ></AgGridReact>
      </StyledGridSection>

      {
        // hide everything if there is an error
        // hide just content until table is loaded to prevent multiple flickering updates but show the box
        !kpiError && (
          <MoreInformation
            forecastInfo={dataLoaded ? forecastInfo : undefined}
            materialListEntity={materialListEntry}
          />
        )
      }
    </DemandValidationTableWrapper>
  );
}

function TitleRenderer(
  params: ICellRendererParams & { materialClassification?: string; columnDefinitions: any },
) {
  const color = params.data.color(params.materialClassification);

  return (
    <TitleWrapper>
      <ColoredDot $color={color}></ColoredDot>
      {getTitle(params.materialClassification)}
    </TitleWrapper>
  );

  function getTitle(materialClassification?: string) {
    const style = params.data.titleStyle(materialClassification);
    if (style === 'highlighted') {
      return <HighlightedTitleStyle>{params.value}</HighlightedTitleStyle>;
    }

    if (style === 'fontWeightBold') {
      return <b>{params.value}</b>;
    }

    if (style === 'indented') {
      return <IndentedTitleStyle>{params.value}</IndentedTitleStyle>;
    }

    if (style === 'gray') {
      return <GrayTitleStyle>{params.value}</GrayTitleStyle>;
    }

    return <>{params.value}</>;
  }
}

const IndentedTitleStyle = styled.span`
  padding-left: 24px;
`;

const HighlightedTitleStyle = styled.span`
  color: ${schaefflerColor};
  font-weight: bold;
`;

const GrayTitleStyle = styled.span`
  color: ${textVeryLightGrey};
`;

const DemandValidationTableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  transition: all 0.25s ease-in-out;
  will-change: width;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ColoredDot = styled.div<{ $color?: string }>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  ${(props) =>
    props.$color &&
    css`
      background-color: ${props.$color};
    `}
`;

export default DemandValidationTable;
